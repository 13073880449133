import { render, staticRenderFns } from "./RobotList.vue?vue&type=template&id=553cded2&scoped=true&"
import script from "./RobotList.vue?vue&type=script&lang=js&"
export * from "./RobotList.vue?vue&type=script&lang=js&"
import style0 from "./RobotList.vue?vue&type=style&index=0&id=553cded2&lang=less&scoped=true&"
import style1 from "./RobotList.vue?vue&type=style&index=1&id=553cded2&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "553cded2",
  null
  
)

export default component.exports