import { render, staticRenderFns } from "./quantification2.vue?vue&type=template&id=165b4466&scoped=true&"
import script from "./quantification2.vue?vue&type=script&lang=js&"
export * from "./quantification2.vue?vue&type=script&lang=js&"
import style0 from "./quantification2.vue?vue&type=style&index=0&id=165b4466&lang=less&scoped=true&"
import style1 from "./quantification2.vue?vue&type=style&index=1&id=165b4466&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "165b4466",
  null
  
)

export default component.exports